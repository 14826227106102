html, body {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
}
#root:not(.loaded) {
	position: relative;
	height: 100%;
}
#root:not(.loaded):before {
	position: absolute;
	top: calc(50% - 12px);
	left: calc(50% - 12px);
	width: 20px;
	height: 20px;
	border: 2px solid currentColor;
	border-top-color: transparent;
	animation: AnimateLoading 0.5s infinite linear;
	border-radius: 50%;
	content: '';
	pointer-events: none;
}
noscript {
	position: absolute;
	display: block;
	width: 100%;
	box-sizing: border-box;
	padding: 20px;
	text-align: center;
}

@keyframes AnimateLoading {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
